import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FloorplanLabel, FloorplanSpan } from '../components/Typography';

const FloorplanMeta = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => props.direction};

  ${props => props.direction === 'row' && `
  label {
    display: block;
  }
  `}
`

const Area = styled.div`
  display: flex;

  label, span {
    display: block;
    text-align: right;
    width: 100%:
  }
  label {
    margin-right: 12px;
    text-align: ${props => props.direction === 'column' ? 'left' : 'right'};
  }
`


export default ({ bedrooms, extraRoom, interiorSqft, exteriorSqft, direction="row", className}) => {
  const { t } = useTranslation();
  return <FloorplanMeta className={ `floorplanMeta ${className}` } direction={direction}>
    <div>
      <FloorplanLabel>{ bedrooms } { t('Bedroom') }</FloorplanLabel>
      { extraRoom && <FloorplanLabel> { /* extra space before curly brace is intentional. leave it. */ t(extraRoom) }</FloorplanLabel> }
    </div>
    <Area direction={direction}>
      <div>
        { interiorSqft && <FloorplanLabel>{ t('Interior') }</FloorplanLabel> }
        { exteriorSqft && <FloorplanLabel>{ t('Exterior') }</FloorplanLabel> }
      </div>
      <div>
        { interiorSqft && <FloorplanSpan>{ interiorSqft } { t('sq ft') }</FloorplanSpan> }
        { exteriorSqft && <FloorplanSpan>{ exteriorSqft } { t('sq ft') }</FloorplanSpan> }
      </div>
    </Area>
  </FloorplanMeta>
} 