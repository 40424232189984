import React from 'react';
import ReactDOM from 'react-dom';
import { initFromBackend } from './i18n';
import './index.css';
import App from './App';
import smoothscroll from 'smoothscroll-polyfill';
import { apiUrl } from './config';

smoothscroll.polyfill();

// import * as serviceWorker from './serviceWorker';

initFromBackend(apiUrl('/options/tesoro-core/translations')).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );

})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
