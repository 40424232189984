import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import i18n from "i18next";
import { Home, Location, Residences, Views, Gallery, Floorplans, Amenities, Videography, Developer, Enquire, ThankYou } from './pages';

const theme = {
  bp: {
    small: 0,
    smallmedium: "768px",
    medium: "800px",
    large: "1024px",
    xlarge: "1280px",
    xxlarge: "1440px"
  }
}

export default () => {
  return (
    <ThemeProvider theme={theme}>
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path={["/zh", "/"]} component={Home} exact />
        <Route path={["/zh/location", "/location"]} component={Location} exact />
        <Route path={["/zh/gallery", "/gallery"]} component={Gallery} exact />
        <Route path={["/zh/residences", "/residences"]} component={Residences} exact />
        <Route path={["/zh/views", "/views"]} component={Views} exact />
        <Route path={["/zh/floorplans/:collection/:bedrooms?/:plan?", "/floorplans/:collection/:bedrooms?/:plan?"]} component={Floorplans} exact />
        <Route path="/floorplans" render={() => <Redirect to="/floorplans/waterfront" />} exact/>
        <Route path="/zh/floorplans" render={() => <Redirect to="/zh/floorplans/waterfront" />} exact/>
        <Route path={["/zh/amenities", "/amenities"]} component={Amenities} exact />
        <Route path={["/zh/videography", "/videography"]} component={Videography} exact />
        <Route path={["/zh/developer", "/developer"]} component={Developer} exact />
        <Route path={["/zh/enquire", "/enquire"]} component={Enquire} exact />
        <Route path={["/zh/thank-you", "/thank-you"]} component={ThankYou} exact />
      </Switch>
    </Router>
    </ThemeProvider>
  );
}
