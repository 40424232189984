import styled from 'styled-components';
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useIntersectionObserver } from '../../hooks';
import { ReactComponent as TesoroLogo } from '../../assets/TesoroLogo.svg';
// import { ReactComponent as ConcertFooterLogo } from '../../assets/Concert_Footer_Logo.svg';
import ConcertLogo from '../../assets/Concert_Properties_Logo_Horizontal_Black--footer.png';
// import { ReactComponent as Twitter } from '../../assets/Twitter.svg';
// import { ReactComponent as Facebook } from '../../assets/Facebook.svg';
import { ReactComponent as Instagram } from '../../assets/Instagram.svg';

const langUrl = (url) => {
  const isZh = window.location.pathname.includes("/zh");
  return `${isZh ? "/zh" : ""}${url}`;
}

const Footer = styled.footer`
  color: #A39383;
  width: 100%;
  padding: 44px 36px 28px;

  @media (min-width: ${props => props.theme.bp.large}) {
    padding: 44px 60px 28px;
  }

  &.lineActive {
    .secondary:before {
      transform: translateX(0);
    }
  }


  a {
    color: inherit;
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      margin-bottom: 24px;

      line,path {
        fill: #A39383;
      }
    }

    .contact {
      text-align: center;
      label, em, a {
        display: block;
      }

      label {
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
      }

      em {
        font-size: 10px;
        line-height: 16px;
        margin-bottom: 16px;
        letter-spacing: .75px;
      }

      a {
        font-size: 11px;
        line-height: 20px;
        letter-spacing: .82px;
      }
    }

    @media (min-width: ${props => props.theme.bp.large}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      .logo {
        margin-bottom: 0;
      }

      .contact {
        text-align: right;
      }
    }
  }

  .secondary {
    margin-top: 38px;
    padding-top: 24px;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {
      content: '';
      border-top: 1px solid #A39383;
      top: 0;
      position: absolute;
      left: 0;
      width: 100%;
      transform: translateX(-100%);
      transition: transform 0.6s ease-in-out;
    }

    .social, .terms {
      margin-bottom: 20px;
    }

    .social {
      a {
        display: inline-block;
        margin-right: 17px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .terms {
      text-align: center;
      letter-spacing: 0.2px;
      font-size: 8px;
      line-height: 11px;
    }

    .logo {
      width: auto;
      height: 13px;

    }

    @media (min-width: ${props => props.theme.bp.large}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: start;

      .social, .terms {
        margin-bottom: 0;
      }

      .terms {
        text-align: left;
        padding: 0 20px;
        max-width: 65%;
      }
    }
  }

`

export default () => {
  const { t } = useTranslation();
  const [ lineActive, setLineActive ] = useState('');
  const ref = useRef();

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setTimeout(() => {
          setLineActive('lineActive');
        }, 300);
      }
    }
  });


  return <Footer ref={ ref } className={`${lineActive}`}>
    <main>
      <Link to={langUrl("/")}><TesoroLogo className="logo" /></Link>
      <div className="contact">
        <label>{ t('Presentation Centre') }</label>
        <em>{ t('Open by Private Appointment') }</em>
        <a href="tel:6046818282">604.681.8282</a>
        <a href="mailto:Tesoro@ConcertProperties.com">Tesoro@ConcertProperties.com</a>
      </div>
    </main>

    <div className="secondary">
      <div className="social">
        <a href="https://www.instagram.com/concerthomes/" target="_blank" rel="noopener noreferrer"><Instagram /></a>
      </div>

      <div className="terms">
        <Trans i18nKey="footerTerms">
        Concert Properties reserves the right to make changes to floor plans, project design, specifications, finishes and features. 
        Suite sizes, dimensions and floor plan layouts are approximate and may vary. 
        Views and renderings shown are for general illustration purposes only and should not be relied upon to accurately represent the actual views that may be available or the actual completed building. 
        Your use of this site is governed by and is subject to the Terms of Use and Disclaimer. 
        By continuing to use this site, you agree to abide by the <a href="http://www.concertproperties.com/about/terms-use" target="_blank" rel="noopener noreferrer">Terms of Use and Disclaimer</a>. <a href="http://www.concertproperties.com/about/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. April 2020. E.&O.E. 
        Copyright 2020 Concert Realty Services Ltd. ® Trademarks of Concert Properties Ltd., used under license where applicable.
        </Trans>
      </div>

      <a href="https://www.concertproperties.com/" target="_blank" rel="noopener noreferrer">
        <img src={ConcertLogo} className="logo" />
      </a>
    </div>

  </Footer>
}