import styled from 'styled-components';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../components/layout/Page';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Captioned from '../components/Captioned';
import { HeroH1, P2 } from '../components/Typography';
import { SpacedSection } from '../components/layout/Sections';
// import { ReactComponent as ConcertLogoWhite } from '../assets/ConcertLogoWhite.svg';
import ConcertLogoWhite from '../assets/Concert_Properties_Logo_Horizontal_White--developer.png';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 95px;
  }

  .dev-logo {
    width: auto;
    height: 16px;
  }
`

const Section = styled.section`
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0 36px;

  @media (min-width: ${props => props.theme.bp.large}) {
    margin-top: 120px;
    margin-bottom: 120px;
  }
`

const Section1 = styled(SpacedSection)`
  color: #A39383;

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    p, p:last-child {
      padding-left: 0;
    }
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    flex-direction: row-reverse;

    >div:first-child {
      p {
        margin-top: 160px;
      }
    }
  }
`

const Section2 = styled(SpacedSection)`
  color: #A39383;

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    p, p:last-child {
      padding-left: 0;
      margin-bottom: 40px;
    }
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    >div:first-child {
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 120px;
        margin-left: 62.597485%;
      }
    }
  }

`
const Section3 = styled(Section)`
  max-width: 906px;
  // padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  color: #A39383;
`
const Section4 = styled(Section)`
  max-width: 906px;
  // padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  color: #A39383;

  h2 {
    color: #A39383;
    max-width: 600px;
    margin: 0 auto 75px;
    padding: 0;
  }
`

const FeatureList = styled.div`

  >div {

    div {
      margin-bottom: 44px;

    }

  }

  label {
    font-family: proxima-nova;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    padding-bottom: 22px;
    border-bottom: 1px solid #A39383;
    margin-bottom: 13px;
    display: block;
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    display: flex;
    justify-content: space-between;

    >div {
      flex: none;
      width: 44.3418%;

      div {

      }
    }
  }

`

export default () => {
  const { t } = useTranslation();
  const transitionRef = useRef();

  return <Page title="Developer" header={{ transitionRef }}>
    <Hero ref={ transitionRef } color="#C5B9AC">
      <Main>
        <HeroH1>
          { t('More Than Thirty Years.') } <br/>
          { t('Over a Hundred Awards.') } <br/>
          { t('Customers For Life.') }
        </HeroH1>
        <img src={ConcertLogoWhite} className="dev-logo" />
      </Main>
    </Hero>

    <Section1 first={357} second={735}>
      <div>
        <P2>{ t('Dedicated to exceeding the ordinary since 1989, Concert Properties has grown into a diversified real estate enterprise with a breadth of operations totalling over $7 billion in assets, backed by the strength of 200,000 Canadians represented by the union and management pension plans that own our company.') }</P2>
      </div>
      <div>
        <Captioned caption={ t('developerCaption1-1') } caption2={ t('developerCaption1-2') }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Navio_TheCreek.jpg" alt="" />
        </Captioned>        
      </div>
    </Section1>

    <Section2 first={628.3} second={439}>
      <div>
        <P2>{ t('With a solid foundation of integrity, service, and quality, Concert Properties is committed to building strong and sustainable communities. We deliver homes that meet superior standards of design and construction, with an industry-leading and uncompromising commitment to service.') }</P2>
        <Captioned caption={ t('developerCaption2-1') } caption2={ t('developerCaption2-2') }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/TheScott_Toronto.jpg" alt="" />
        </Captioned>
      </div>
      <div>
        <Captioned caption={ t('developerCaption3-1') } caption2={ t('developerCaption3-2') }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Patina-Vancouver.jpg" alt="" />
        </Captioned>        
      </div>
    </Section2>

    <Section3>
      <Captioned caption={ t('developerCaption4-1') } caption2={ t('developerCaption4-2') }>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/365-Waterfront-Victoria.jpg" alt="" />
      </Captioned>
    </Section3>

    <Section4>
      <HeroH1 as="h2">{ t('Five Reasons to Choose Concert Properties') }</HeroH1>

      <FeatureList>
        <div>
          <div>
            <label>{ t('Experience') }</label>
            <P2>{ t('Through the experience and skill of our longstanding employees and our industry-leading architects, engineers and designers, Concert Properties has proudly developed 12,000 homes across British Columbia and Ontario.') }</P2>
          </div>
          <div>
            <label>{ t('Integrity') }</label>
            <P2>{ t('The sterling reputation we have built over 30 years is the result of holding tightly to our principles and consistently delivering on promises, while improving the lives of those who call our communities home.') }</P2>
          </div>
          <div>
            <label>{ t('Quality') }</label>
            <P2>{ t('In order to deliver homes that lead the industry in standards of quality and homebuyer satisfaction, we ensure that every decision and action is forward thinking and customer focused.') }</P2>
          </div>
        </div>

        <div>
          <div>
            <label>{ t('Assurance') }</label>
            <P2>{ t('Homes are fully protected by the Concert Properties “Commitment to You” Warranty, which includes a ten-year structural guarantee, six-year water penetration protection and two years coverage for workmanship and materials.') }</P2>
          </div>
          <div>
            <label>{ t('Service') }</label>
            <P2>{ t('Our dedicated customer service team, Concert On-Call, is committed to addressing any issues that may arise during the first two years in a manner that exceeds homeowners’ expectations.') }</P2>
          </div>
        </div>

      </FeatureList>
    </Section4>
  </Page>
}