import styled from 'styled-components';

const HeroH1 = styled.h1`
  font-family: 'AltrinchamW01';
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 1.08px;
  text-transform: uppercase;
  color: white;
  text-align: center;
  margin: 0;
  word-break: normal;

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.96px;
    padding: 0 36px;

    br {
      display: none;
    }
  }
`

const HeroH2 = styled.h2`
  // font-family: 'AltrinchamW01';
  font-family: proxima-nova;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7142857em;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  color: white;
  text-align: center;
  margin: 30px 0 0;
  word-break: normal;

  .lang-zh & {
    font-size: 16px;
  }

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    font-size: 12px;
    line-height: 1.4166667em;
    letter-spacing: 2.4px;
    padding: 0 36px;

    br {
      display: none;
    }

    .lang-zh & {
      font-size: 14px;
    }
  }
`

const H3 = styled.h3`
  font-family: 'AltrinchamW01';
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.84px;
  text-transform: uppercase;
  margin-bottom: 25px;
  word-break: normal;
`

const P = styled.p`
  letter-spacing: 0.84px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }

  .lang-zh & {
    font-size: 16px;
  }
`

const P2 = styled.p`
  letter-spacing: 0.9px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4666666em;
  margin-bottom: 0;

  .lang-zh & {
    font-size: 16px;
  }
`

const FloorplanH3 = styled.h3`
  font-family: 'AltrinchamW01';
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  margin: 0;
`

const FloorplanLabel = styled.label`
  font-family: proxima-nova;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;

`

const FloorplanSpan = styled.span`
  font-family: proxima-nova;
  letter-spacing: 0.78pz;
  font-size: 13px;
  line-height: 20px;

`

const ButtonSpan = styled.span`
  background-color: #C4B8AC;
  color: white;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 3.3px;
  line-height: 2.5454545em;
  padding: 0 30px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  word-break: keep-all;

  ${props => props.width ? `width: ${props.width}px;` : ''}

  &.inverted {
    background-color: white;
    color: #C4B8AC;
  }

  .lang-zh & {
    font-size: 14px;
  }
`


export { HeroH1, HeroH2, H3, P, P2, FloorplanH3, FloorplanLabel, FloorplanSpan, ButtonSpan }