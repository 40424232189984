import styled from 'styled-components';
import React from 'react';
import i18n from "i18next";

const LangPicker = styled.div`
  a {
    color: inherit;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 0.5em;
    font-size: inherit;

    &.active {
      font-weight: 900;
    }
  }

`

export default ({ className }) => {
  const zhFragment = "/zh";
  const pathname = window.location.pathname;
  const enUrl = pathname.replace(zhFragment, "");
  const zhUrl = `${pathname.includes(zhFragment) ? "" : zhFragment}${pathname}`;

  return <LangPicker className={className}>
    <a href={enUrl} className={i18n.language === 'en' ? 'active' : ''}>EN</a>
    <a href={zhUrl} className={i18n.language === 'zh' ? 'active' : ''}>中文</a>
  </LangPicker>

}