import styled from "styled-components";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Page from "../components/layout/Page";
import Hero from "../components/Hero";
import { HeroH1, HeroH2, H3, P } from "../components/Typography";
import { SpacedSection } from "../components/layout/Sections";
import VideoWrapper from "../components/VideoWrapper";
import TransitionBlock from "../components/TransitionBlock";

const VideoSection = styled(SpacedSection)`
  @media (min-width: ${(props) => props.theme.bp.large}) {
    align-items: center;
  }
`;

export default () => {
  const { t } = useTranslation();
  const transitionRef = useRef();

  return (
    <Page title="Videography" header={{ transitionRef }}>
      <Hero
        ref={transitionRef}
        coverImage="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/06/ResidencesHero.jpg"
      >
        <HeroH1>{t("Experience TESORO")}</HeroH1>
        <HeroH2>{t("These treasured moments.")}</HeroH2>
      </Hero>

      <VideoSection first={254} second={795}>
        <div>
          <TransitionBlock
            opacityDuration="0.6s"
            offsetDuration="0.8s"
            fromOffsetY={80}
            threshold={0}
          >
            <H3>{t("I")}</H3>
            <P>{t("The illumination of each day.")}</P>
          </TransitionBlock>
        </div>
        <div>
          <VideoWrapper
            image="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/10/Fire_VideoThumbnail.jpg"
            video="https://player.vimeo.com/video/469107250"
          />
        </div>
      </VideoSection>

      <VideoSection first={795} second={254}>
        <div>
          <VideoWrapper
            image="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/10/Air_VideoThumbnail.jpg"
            video="https://player.vimeo.com/video/469113128"
          />
          <img src="" alt="" />
        </div>
        <div>
          <TransitionBlock
            opacityDuration="0.6s"
            offsetDuration="0.8s"
            fromOffsetY={80}
            threshold={0}
          >
            <H3>{t("II")}</H3>
            <P>{t("The promise of possibility, borne on a breeze.")}</P>
          </TransitionBlock>
        </div>
      </VideoSection>

      <VideoSection first={124} second={795} gutterSecond={191}>
        <div>
          <TransitionBlock
            opacityDuration="0.6s"
            offsetDuration="0.8s"
            fromOffsetY={80}
            threshold={0}
          >
            <H3>{t("III")}</H3>
            <P>{t("A fluid luxury, life by the sea.")}</P>
          </TransitionBlock>
        </div>
        <div>
          <VideoWrapper
            image="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/10/Water_VideoThumbnail.jpg"
            video="https://player.vimeo.com/video/469839070"
          />
        </div>
      </VideoSection>

      <VideoSection first={795} second={175} gutterSecond={150}>
        <div>
          <VideoWrapper
            image="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/10/Earth_VideoThumbnail.jpg"
            video="https://player.vimeo.com/video/469120059"
          />
        </div>
        <div>
          <TransitionBlock
            opacityDuration="0.6s"
            offsetDuration="0.8s"
            fromOffsetY={80}
            threshold={0}
          >
            <H3>{t("IV")}</H3>
            <P>{t("Texture, tactility, quality. A fine appreciation.")}</P>
          </TransitionBlock>
        </div>
      </VideoSection>

      <VideoSection first={211} second={795} gutterFirst={116}>
        <div>
          <TransitionBlock
            opacityDuration="0.6s"
            offsetDuration="0.8s"
            fromOffsetY={80}
            threshold={0}
          >
            <H3>{t("V")}</H3>
            <P>{t("Our most treasured moments.")}</P>
          </TransitionBlock>
        </div>
        <div>
          <VideoWrapper
            image="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/10/Home_VideoThumbnail.jpg"
            video="https://player.vimeo.com/video/469839281"
          />
        </div>
      </VideoSection>
    </Page>
  );
};
