import styled from 'styled-components';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../components/layout/Page';
import Captioned from '../components/Captioned';
import Hero from '../components/Hero';
import Image from '../components/Image';
import { HeroH1, HeroH2, P2 } from '../components/Typography';
import { PercentageSection, SpacedSection } from '../components/layout/Sections';

const Section1 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    p {
      margin-left: 37.116564%;
      margin-bottom: 110px;
    }
  }
`
const Section2 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    p {
      margin-left: 48.3974358%;
      margin-bottom: 229px;
    }
  }
`
const Section3 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    p {
      width: 201px;
      margin-bottom: 392px;
    }
  }
`
const Section4 = styled(PercentageSection)`
`
const Section5 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    p {
      text-align: center;
      margin-bottom: 101px;
    }
  }
`
const Section7 = styled(SpacedSection)`
  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    >div:first-child {
      >div:first-child {
        margin-bottom: 40px;
      }
    }
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    >div:first-child {
      >div:first-child {
        width: 80.753138%;
        margin-bottom: 265px;
      }
    }

    >div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    p {
      width: 294px;
      margin-top: 187px;
      margin-bottom: 94px;
    }
  }


`
const Section8 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    >div:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }


    p {
      width: 294px;
      margin-top: 272px;
      margin-bottom: 94px;
    }
  }
`
const Section9 = styled(SpacedSection)`
  align-items: center;
`

const Section10 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div:first-child {
      display: flex;
      justify-content: flex-end;
    }
    p {
      width: 173px;
    }

    >div:last-child {
      >div {
        width: 71.26619527%;
      }
    }
  }
`

const Section12 = styled(PercentageSection)`
`
const Section13 = styled(PercentageSection)`
  a {
    display: inline-block;
    margin-top: 80px;
  }

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    >div:last-child {
      text-align: center;
      p {
        padding-left: 0;
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    >div:last-child {
      width: 374px;
      margin-top: 113px;
      text-align: left;
    }
    a {
      display: block;
      margin-top: 55px;
    }
  }

`

export default () => {
  const { t } = useTranslation();
  const transitionRef = useRef();

  return <Page title="Location" header={{ transitionRef }}>
    <Hero ref={ transitionRef } coverImage="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/06/LocationHero.jpg">
      <HeroH1>
        { t('False Creek') }
      </HeroH1>
      <HeroH2>
        { t('A city’s urban and natural treasures.') } <br/>
        { t('Your daily existence.') }
      </HeroH2>
    </Hero>
    <Section1 gutterFirst={158.53} first={326} second={419.25} gutterSecond={118.75}>
      <div>
        <P2>{ t('Dragon boats, aquabuses, sailboats and yachts bustle about in the cove. At your feet, a winding seam of seawall tempts you toward the city’s finest dining, galleries, neighbourhoods, and shops.') }</P2>
        <Captioned caption={ t("Creekside Paddling Centre") } caption2={ t("False Creek") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/PaddlingCentre.jpg" alt="" />
        </Captioned>        
      </div>
      <div>
        <Captioned caption={ t("Science World") } caption2={ t("1455 Quebec St") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/ScienceWorld.jpg" alt="" />
        </Captioned>        
      </div>
    </Section1>

    <Section2 first={436} second={312} gutterFirst={343}>
      <div>
        <Captioned caption={ t("Dragon Boats") } caption2={ t("False Creek") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/DragonBoats.jpg" alt="" />
        </Captioned>        
      </div>
      <div>
        <P2>{ t('Where West Coast waterfront meets cultured urban living.') }</P2>
        <Captioned caption={ t("BMO Theatre Centre") } caption2={ t("162 W 1st Ave") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/BMOTheatre.jpg" alt="" />
        </Captioned>        
      </div>
    </Section2>

    <section>
      <Captioned inline color="white" caption={ t("Vancouver") } caption2={ t("British Columbia") }>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Vancouver_Morning_2000px.jpg" alt="" />
      </Captioned>        
    </section>

    <Section3 first={275} second={417} gutterFirst={193.54} gutterSecond={121}>
      <div>
        <P2>{ t('The winding seawall, the calm water, the colourful markets of Granville Island.') }</P2>
        <Captioned caption={ t("Granville Island") } caption2={ t("False Creek") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/GranvilleIsland.jpg" alt="" />
        </Captioned>        
      </div>
      <div>
        <Captioned caption={ t("Seawall") } caption2={ t("Vancouver") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/Seawall.jpg" alt="" />
        </Captioned>        
      </div>
    </Section3>

    <Section4 portionLeft={414} portionRight={393}>
      <Captioned caption={ t("Nook") } caption2={ t("195 W 2nd Ave") }>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/Nook.jpg" alt="" />
      </Captioned>        
    </Section4>


    <Section5 first={405} second={285} gutterFirst={243} gutterSecond={156}>
      <div>
        <Captioned caption={ t("Olympic Village Square") } caption2={ t("False Creek") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/OlympicPlaza_Bird.jpg" alt="" />
        </Captioned>        
      </div>
      <div>
        <P2>{ t('A local cocktail. A walk to the park. The sights and sounds unique to False Creek.') }</P2>
        <Captioned caption={ t("Hinge Park") } caption2={ t("215 W 1st Ave") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/HingePark.jpg" alt="" />
        </Captioned>        
      </div>
    </Section5>

    <section>
      <Captioned inline color="white" caption={ t("Vancouver") } caption2={ t("British Columbia") }>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Vancouver_Aerial_2000px.jpg" alt="" />
      </Captioned>        
    </section>

    <Section7 first={478} second={447}>
      <div>
        <Captioned caption={ t("Kissa Tanto") } caption2={ t("263 E Pender St") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/KissaTanto.jpg" alt="" />
        </Captioned>        
        <Captioned caption={ t("Equinox Gallery") } caption2={ t("525 Great Northern Way") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/EquinoxGallery.jpg" alt="" />
        </Captioned>        
      </div>
      <div>
        <Captioned caption={ t("Torafuku") } caption2={ t("958 Main St") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/Torafuku.jpg" alt="" />
        </Captioned>        
        <P2>{ t('The art, the food, the nightlife. The knowledge of a neighbourhood’s intimate spaces and intricate flavours.') }</P2>
      </div>
    </Section7>

    <Section8 first={570.56} second={372.79} gutterFirst={191}>
      <div>
        <Captioned caption={ t("St Lawrence") } caption2={ t("269 Powell St") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/St-Lawrence.jpg" alt="" />
        </Captioned>        
      </div>
      <div>
        <Captioned caption={ t("Neighbour/Woman") } caption2={ t("45 Powell St") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/NeighbourWoman.jpg" alt="" />
        </Captioned>        
        <P2>{ t('The finest of dining. The curated clothiers. The textures of a lifestyle.') }</P2>
      </div>
    </Section8>

    <Section9 first={293} second={487}>
      <div>
        <Captioned caption={ t("Poliform") } caption2={ t("779 W Pender St") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Poliform_Detail.jpg" alt="" />
        </Captioned>        
      </div>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/Poliform.jpg" alt="" />
      </div>
    </Section9>

    <Section10 first={293} second={717}>
      <div>
        <P2>{ t('Our urban landscape unfolds as an art form — a window display that presents like a painting; a restaurant’s exquisite design.') }</P2>
      </div>
      <div>
        <Captioned caption={ t("Nightingale") } caption2={ t("1017 W Hastings St") }>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Nightingale.jpg" alt="" />
        </Captioned>        
      </div>
    </Section10>

    <Section12 portionLeft={122} portionRight={423}>
      <Captioned caption={ t("Elisa") } caption2={ t("1109 Hamilton St") }>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Elisa.jpg" alt="" />
      </Captioned>        
    </Section12>

    <Section13 portionLeft={191} portionRight={191}>
      <Captioned caption={ t("The Orpheum") } caption2={ t("601 Smithe St") }>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Orpheum.jpg" alt="" />
      </Captioned>
      <div>
        <P2>{ t('Our city enchants us with world-class cuisine, elegant theatre, towering mountains and shimmering sea. The world outside your door.') }</P2>
      </div>
    </Section13>

  </Page>
}