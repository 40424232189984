import styled from 'styled-components';
import React, { useState } from 'react';
import FormSelect from '../components/FormSelect';
import { useTranslation } from 'react-i18next';
import { useIntersectionObserver } from '../hooks';

const Menu = styled.div`

  position: fixed;
  background: black;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  color: white;
  transition: transform 0.3s ease;
  transform: translateY(-100%);

  &.active {
    transform: translateY(0);
  }

`

const MenuItems = styled.div`
  display: flex;
  width: calc(100% - 150px - 100px);
  margin: 0 150px 0 100px;
  height: 100px;
  padding-top: 45px;

  .menuItem {
    flex: 0 0 33.3333%;
    padding: 0 10px;    
  }

  .formElementDropdown {
    height: 32px;
    border: none;

    &.isPlaceholder {
      label {
        color: #C5B9AC;
      }
    }


    label {
      cursor: pointer;
      font-family: proxima-nova;
      font-size: 9px;
      font-weight: 500;
      letter-spacing: 1.8px;
      line-height: 11px;
      width: 100%;
      display: block;
      text-transform: uppercase;
      position: relative;
      z-index: 1;
    }

    &:after {
      transform: none;
      top: 7px;
    }
  }

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    margin: 0 30px 0 30px;
    width: calc(100% - 30px - 30px);
    flex-direction: column;
    height: auto;
    background: black;
    padding-bottom: 10px;

    .formElementDropdown {
      border: none;

      label {
        font-size: 11px;
        width: 100%;
        text-align: center;
        display: block;
      }

      &:after {
        top: 2px;
      }
    }
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    width: calc(100% - 170px - 120px);
    margin: 0 170px 0 120px;

    .formElementDropdown {

      label {
        font-size: 12px;
        letter-spacing: 2.4px;
        line-height: 20px;
      }
    }
  }


  @media (min-width: ${props => props.theme.bp.xlarge}) {
    width: calc(100% - 200px - 150px);
    margin: 0 200px 0 150px;

    .menuItem {
      padding: 0 30px;
    }

    .formElementDropdown {

      label {
        // padding-left: 20px;
      }

      &:after {
        // right: 20px;
      }
    }
  }
`

export default ({collection, selectCollection, bedrooms, selectBedrooms, plan, selectPlan, bedroomOptions, filteredFloorplans, heroRef}) => {
  const { t } = useTranslation();
  const [ isVisible, setIsVisible ] = useState(false);

  useIntersectionObserver({
    target: heroRef,
    rootMargin: '-101px',
    onIntersect: ([{ isIntersecting }], observerElement) => {
      setIsVisible(!isIntersecting);
    }
  })

  const collectionSelectOptions = [
    { value: "waterfront", label: t('Waterfront Collection') },
    { value: "city", label: t('City Collection') },
    { value: "townhome", label: t('Townhome Collection') }
  ];

  const bedroomSelectOptions = bedroomOptions.map(obj => ({value: obj.slug, label: obj.label}));
  bedroomSelectOptions.push({value: 'all', label: t('View All')});

  const planSelectOptions = filteredFloorplans
      .map(obj => ({
        value: obj.slug,
        label: obj.name === 'Townhome' ? `${ obj.name } ${ obj.unit }` : obj.name,
        disabled: obj.isSoldOut /* in future there could be multiple data sources that define this item as disabled */
      }))
      .map(obj => ({
        ...obj,
        ['label']: obj.disabled ? `${obj.label} — ${t('Sold')}` : obj.label
      }))
  planSelectOptions.push({value: 'all', label: t('View All')});

  return <Menu className={ isVisible ? 'active' : ''}>
    <MenuItems>
      <div className="menuItem">
        <FormSelect className="taupe" selected={collection} onChange={selectCollection} options={ collectionSelectOptions } />
      </div>
      <div className="menuItem">
        <FormSelect className="taupe" selected={bedrooms} onChange={selectBedrooms} placeholderValue="all" placeholder={ t('Select Bedrooms') } options={ bedroomSelectOptions } />
      </div>
      <div className="menuItem">
        <FormSelect className="taupe" selected={plan} onChange={selectPlan} placeholderValue="all" placeholder={ t('Select Plan Type') } options={ planSelectOptions } />
      </div>
    </MenuItems>
  </Menu>
}