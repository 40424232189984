import React, { useState, useEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import Captioned from './Captioned';
import { ReactComponent as DropDownArrow } from '../assets/DropDownArrow.svg';

const HeroContainer = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${props => props.bgColor ? `background-color: ${props.bgColor};` : ''}

  .scrollDown {
    position: absolute;
    bottom: 30px;
    width: 20px;
    height: auto;
    cursor: pointer;
  }
`

const Cover = styled.div`
  z-index: 1;
`

const CoverImageWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  
  >div {
    width: 100%;
    height: 100%;    
  }

  video, img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 4s ease-in-out;
  }

`

const CoverImage = ({ src, caption, caption2 }) => {
  const [ scale, setScale ] = useState(1);
  const ref = useRef();

  useEffect(() => {

    // client didn't want this on scroll. Just do initial. 

    setScale(1.05);

    // let ticking = false;

    // const onScroll = () => {
    //   if (!ticking) {
    //     window.requestAnimationFrame(function() {
    //       doAnimation(window.scrollY);
    //       ticking = false;
    //     });

    //     ticking = true;
    //   }
    // }

    // const doAnimation = (pos) => {
    //   const top = ref.current.offsetTop;
    //   const height = ref.current.offsetHeight;
    //   const bottom = top + height;
    //   const y = Math.min(Math.max(pos, top), bottom);
    //   const p = y / height;
      
    //   const initial = 1.05;
    //   const factor = 0.4;

    //   setScale(initial + p * factor);
    // }

    // onScroll(); // call for first render;
    // window.addEventListener('scroll', onScroll);

    // return () => {
    //   window.removeEventListener('scroll', onScroll);
    // }

  }, [])

  const image = <img ref={ref} src={src} style={{ transform: `scale(${scale}`}} alt="" />

  return <CoverImageWrap>{ caption 
    ? <Captioned inline color="white" caption={caption} caption2={caption2}>{ image }</Captioned>
    : image
  }</CoverImageWrap>
}


export default forwardRef(({ color=false, coverImage=false, caption, caption2, downArrow=true, children }, ref)  => {

  const scrollDown = () => {
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' }) 
  }

  return <HeroContainer ref={ ref } bgColor={color}>
    { coverImage && <CoverImage src={ coverImage } caption={ caption } caption2={ caption2 } /> }
    <Cover>
      { children }
    </Cover>
    { downArrow && <DropDownArrow  onClick={ scrollDown } className="scrollDown" /> }
  </HeroContainer>;
})
