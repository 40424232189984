import styled from 'styled-components';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from "react-hook-form";
import FormSelect from './FormSelect';

const SparkForm = styled.div`
  width: 100%;
  // margin: 0 auto;
  // padding: 0 36px;
  position: relative;

  .spaceBetween {
    @media (min-width: ${props => props.theme.bp.medium}) {
      display: flex;
      justify-content: space-between;
    }
  }

  .spaceAround {
    display: flex;
    justify-content: space-around;    
  }

  input::placeholder,
  textarea::placeholder,
  input[type=text], 
  input[type=email], 
  textarea,
  select,
  button,
  label {
    font-family: proxima-nova;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    text-align: center;
    line-height: 20px;
    font-size: 11px;
    letter-spacing: 1.8px;
    outline: none;
  }

  label {
    display: block;
    padding: 0.5em 0;
  }

  input[type=text],
  input[type=email], 
  textarea,
  select {
    padding: 0.5em 1em;
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    width: 100%;
  }

  textarea {
    resize: none;
    padding: 5px 1em;
  }

  .error {
    margin-top: 6px;
    color: #5c5c5c;
    font-size: 9px;
    letter-spacing: 2.4px;
    text-align: center;
    line-height: 15px;
  }

`

const FormRow = styled.div`
  display: flex;
  margin: 0 -10px;
  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    flex-direction: column;
  }

`

const FormItem = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 10px;
  margin-bottom: 40px;

  &.last {
    margin-bottom: 0;
  }
`

const FormRadio = styled.div`

  input {
    display: none;
  }

  label {
    padding-left: 24px;
    position: relative;
    display: block;
    text-align: left;
    cursor: pointer;

    &:before, &:after {
      content: '';
      border-radius: 12px;
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translate(-50%, -50%);
    }

    &:before {
      border: 1px solid white;
      width: 12px;
      height: 12px;      
    }

    &:after {
      display: none;
      width: 7px;
      height: 7px;
      background-color: white;
    }
  }

  input:checked + label:after {
    display: block;
  }

`

const FormTerms = styled.div`
  text-align: center;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  transition: max-height 600ms ease-out;
  max-height: 0;
  overflow: hidden;
  margin-top: 20px;

  @media (min-width: ${props => props.theme.bp.large}) {

    position: absolute;

  }


  &.active {
    max-height: 80px;
  }
`

const DisclaimerButton = styled.button`
  background: none;
  border: none;
  // width: 150px;
  cursor: pointer;
  position: relative;
  padding-right: 20px;

  && {
    text-align: left;
  }
`

const SubmitButton = styled.button`
  background: none;
  border: none;
  width: 145px;
  cursor: pointer;
  letter-spacing: 3.3px;
  background-color: white;
  margin: 115px auto 0;

  && {
    color: #C4B8AC;
    font-size: 12px;
    font-weight: 600;
    line-height: 28px;
  }
`

const objectValue = (name, obj) => {
  let cur = {...obj};
  const keys = name.replace(/\]/g, '').split('[');
  for (let i=0; i<keys.length; i++) {
    let key = keys[i];
    if (cur[key]) {
      cur = cur[key];
    }
  }
  return cur;
}

const FormInput = ({ type, name, placeholder, required=true, register, errors, validate={}, errorMessages={} }) => {
  const { t } = useTranslation();
  const label = `${placeholder}${required ? '*' : ''}`;
  const id = name.replace(/[[\] ]/g,'');
  const ref = register ? register({ required, ...validate }) : null;
  const error = objectValue(name, errors);

  const messages = {
    required: t('This field is required.'),
    pattern: t('This field seems to be invalid.'),
    default: t('This field seems to be invalid.'),
    maxLength: t('This field is too long.'),

    ...errorMessages
  }

  const errorMessage = error && error.type ? (messages[error.type] || messages.default) : null;

  return <>
    <label className="sr-only" htmlFor={id}>{ label }</label>
    <input type={type} ref={ref} id={id} name={name} placeholder={ label } data-parsley-pattern-message="Numeric and special characters are not allowed." data-parsley-pattern="/^[A-Za-z\s]+$/"  />
    { errorMessage && <div className="error">{errorMessage}</div> }
  </>

}

export default ({ className }) => {
  const heightMultiplier = 20;
  const { t } = useTranslation();
  const formRef = useRef();
  const [ showDisclaimer, setShowDisclaimer ] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [ textareaHeight, setTextareaHeight ] = useState(heightMultiplier + 11);

  useEffect(() => {
    document.getElementById("are_you_simulated").style.display = "none";     // Hides spam trap
  });

  const onSubmit = data => {
    formRef.current.submit();
  }

  const updateTextareaHeight = (e) => {
    setTextareaHeight(e.target.value.split("\n").length * heightMultiplier + 11);
  }

  const titleOptions = [
    { value: "Mr.", label: t('Mr.') },
    { value: "Mrs.", label: t('Mrs.') },
    { value: "Miss.", label: t('Miss.') },
    { value: "Ms.", label: t('Ms.') },
    { value: "Dr.", label: t('Dr.') }
  ]

  return <SparkForm className={className}>
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} acceptCharset="UTF-8" method="post" action="https://spark.re/concert-properties/tesoro/register/tesoro-website" noValidate>

      <input name="utf8" type="hidden" value="&#x2713;" />
      <input type="hidden" name="authenticity_token" value="fULo5X64HKtbArFgehZPTl1UyaP+h21UJtVpyxUeXgJ6JqnY6xD/pqnSNsGRZQ9asas07+SsbT7MIJs/ijH2Gg==" />

      <input type="hidden" name="source" id="source" value="Website Form" />

      <input type="hidden" name="redirect_success" value="https://tesorobyconcert.com/thank-you" />
      <input type="hidden" name="redirect_error" value="https://tesorobyconcert.com/error"/>


      <FormRow>
        <FormItem>
          <label className="sr-only" htmlFor="contact_title">{ t('Title') }</label>
          <FormSelect name="contact[title]" id="contact_title" placeholder={ t('Title') } className="form" options={ titleOptions } />
        </FormItem>

        <FormItem>
          <FormInput type="text" name="contact[first_name]" placeholder={ t('First name') } register={register} errors={errors} validate={{maxLength: 40, pattern: /^[A-Za-z\s]+$/ }} errorMessages={{pattern: t('Numeric and special characters are not allowed.')}} />
        </FormItem>

        <FormItem>
          <FormInput type="text" name="contact[last_name]" placeholder={ t('Last name') } register={register} errors={errors} validate={{maxLength: 40, pattern: /^[A-Za-z\s]+$/ }} errorMessages={{pattern: t('Numeric and special characters are not allowed.')}} />
        </FormItem>

      </FormRow>

      <FormRow>
        <FormItem>
          <FormInput type="email" name="contact[email]" placeholder={ t('Email address') } register={register} errors={errors} validate={{pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/ }} errorMessages={{pattern: t('This field should be a valid email.')}} />
        </FormItem>        

        <FormItem>
          <FormInput type="text" name="contact[phone]" placeholder={ t('Primary phone number') } register={register} errors={errors} validate={{pattern: /^[1-9][0-9][0-9][- ]?[0-9][0-9][0-9][- ]?[0-9][0-9][0-9][0-9]$/}} errorMessages={{pattern: t('This field should be a valid 10 digit phone number.')}}/>
        </FormItem>        
      </FormRow>

      <FormRow>
        <FormItem>
          <FormInput type="text" name="contact[postcode]" placeholder={ t('Postal code') } register={register} errors={errors} validate={{pattern: /^[A-Za-z][0-9][A-Za-z][- ]?[0-9][A-Za-z][0-9]$/}} />
        </FormItem>        
        <FormItem>
          <div className="spaceAround">
            <label>{ t('Are you a realtor?') }*</label>
            <FormRadio>
              <input ref={register({ required: true })} value="true" type="radio" name="agent" id="agent_yes" />
              <label htmlFor="agent_yes">{ t('Yes') }</label>
            </FormRadio>
            <FormRadio>
              <input ref={register({ required: true })} value="false" type="radio" name="agent" id="agent_no" />
              <label htmlFor="agent_no">{ t('No') }</label>
            </FormRadio>          
          </div>
          { errors?.agent && <div className="error">{t('This field is required.')}</div> }
        </FormItem>        
      </FormRow>

      <FormRow>
        <FormItem>
          <label className="sr-only" htmlFor="comments">{ t('Additional comments') }</label>
          <textarea id="comments" name="contact[comments]" placeholder={ t('Additional comments') } style={ { height: textareaHeight } }  onChange={updateTextareaHeight}></textarea>
        </FormItem>
      </FormRow>
  
      <FormRow>
        <FormItem className="last">
          <div className="spaceBetween">
            <div>
              <div>
                <FormRadio>
                  <input ref={register({ required: true })} type="checkbox" value="Yes" name="answers[6791][answers][]" id="answers_6791_yes" />
                  <label htmlFor="answers_6791_yes">{ t('I confirm that I wish to receive the latest information.') }*</label>
                </FormRadio>
                { errors?.answers && errors.answers[6791]?.answers[""]?.type && <div className="error">{t('This field is required.')}</div> }
              </div>
              <DisclaimerButton className="formElementDropdown" type="button" onClick={() => setShowDisclaimer(!showDisclaimer)}>{ t('View Terms of Registration') }</DisclaimerButton>
            </div>
            <label>* { t('Required Fields') }</label>
          </div>
        </FormItem>
      </FormRow>


      <FormTerms className={showDisclaimer ? 'active' : ''}>
        <Trans i18nKey="enquireTerms">
          By clicking SUBMIT below, I am permitting Concert Realty Services Ltd. and its affiliates and partners (“Concert”) to contact me with promotional 
          messages such as newsletters, announcements, press releases and event invitations regarding their products and services. 
          I understand this may take the form of communication via email, telephone or post and consent to the collection, use and disclosure of 
          the information submitted for the purposes disclosed on this page and for the purposes set out in Concert’s <a href="http://www.concertproperties.com/about/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. 
          If I want to receive email communication, I must give explicit consent via the tick box located above. 
          I may withdraw my consent at any time.
        </Trans>
     </FormTerms>

      <input type="text" name="are_you_simulated" id="are_you_simulated" placeholder="Leave this field blank" style={{visibility: 'hidden'}} />

      <FormRow>
        <SubmitButton type="submit">{ t('Submit') }</SubmitButton>
      </FormRow>

    </form>
  </SparkForm>

}