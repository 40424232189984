import styled from 'styled-components';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../components/layout/Page';
import Hero from '../components/Hero';
import Image from '../components/Image';
import { HeroH1, HeroH2, H3, P, P2, ButtonSpan } from '../components/Typography';
import { Section, SpacedSection, PercentageSection, InfoSection } from '../components/layout/Sections';

const Section2 = styled(Section)`

  @media (min-width: ${props => props.theme.bp.large}) {
    padding: 0 60px 0 9.621875%;

    p {
      margin-top: 64px;
    }

  }
`


const Section3 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div:first-child {
      margin-top: 164px;
    }

    p {
      margin-top 150px;
      max-width: 154px;
    }  
  }
`

const Section4 = styled(Section)`
  @media (min-width: ${props => props.theme.bp.large}) {
    p {
      margin: 64px auto 0;
      max-width: 364px;
      text-align: center;
    }
  }
`

const Section5 = styled(PercentageSection)`
`
const Section5Inner = styled.section`
  @media (min-width: ${props => props.theme.bp.large}) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    >div:first-child {
      width: 61.424731%;
      flex-shrink: 0;
    }
    >div:last-child {
      padding-right: 79px;
      h3 {
        margin-bottom: 37px;
      }
      p {
        margin-bottom: 56px;
      }
    }
  }

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    >div:last-child {
      margin-top: 40px;
      p {
        padding-left: 0;
        margin: 0 0 56px;
      }
    }
  }


`

export default () => {
  const { t } = useTranslation();
  const transitionRef = useRef();

  return <Page title="Amenities" header={{ transitionRef }}>
    <Hero ref={ transitionRef } coverImage="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Amenities_Header.jpg">
      <HeroH1>
        { t('Stunning Spaces For Each') } <br/>
        { t('Treasured Moment') }
      </HeroH1>
      <HeroH2>
        { t('Indulgence, serenity, social gathering.') }
      </HeroH2>
    </Hero>
    <InfoSection first={234} second={866}>
      <div>
        <div>
          <H3>{ t('Amenities') }</H3>
          <P>{ t('Amenities that set the stage for each treasured moment. Indulgence, serenity, social gathering. The luxury of home.') }</P>
        </div>
      </div>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Conceirge_Lobby.jpg" alt="" />
      </div>
    </InfoSection>

    <SpacedSection first={942} second={150}>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Fireside_Lounge.jpg" alt="" />
      </div>
      <div>
        <P2>{ t('A warm and inviting fireside lounge.') }</P2>
      </div>
    </SpacedSection>

    <Section2>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Plunge_Pool_Amenity.jpg" alt="" />
      <P2>{ t(`A European spa experience: plunge pools hot and cold, steam room and sauna.`) }</P2>
    </Section2>

    <Section3 first={516} second={572}>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/FalseCreek_PaddlingCentre_FitnessAmenity.jpg" alt="" />
      </div>
      <div>
        <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Kettlebell_Fitness_Amenity.jpg" alt="" />
        <P2>{ t('Fitness facilities for daily wellness. Outside your door, boats to paddle, pathways to run, mountains to climb.') }</P2>
      </div>
    </Section3>

    <Section4>
      <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Outdoor_Terrace_Amenity.jpg" alt="" />
      <P2>{ t('Chef’s kitchen, dining room, and outdoor terrace for lingering meals and memorable evenings.') }</P2>
    </Section4>

    <Section5 portionLeft={125} portionRight={411}>
      <Section5Inner>
        <div>
          <Image src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Artwork_Image.jpg" alt="" />
        </div>
        <div>
          <H3>{ t('Curated Artwork') }</H3>
          <P>{ t('Immersed in the artistry of TESORO, surrounded by a curated collection of fine artwork. Inspired in every moment.') }</P>
          <a href="https://tesorobyconcert.com/wordpress/wp-content/uploads/2024/03/2024-02-22-Tesoro-Curated-Artwork.pdf" target="_blank"><ButtonSpan>{ t('Collection') }</ButtonSpan></a>
        </div>
      </Section5Inner>
    </Section5>

  </Page>
}