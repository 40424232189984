import styled, { ThemeContext } from 'styled-components';
import React, { useState, useEffect, forwardRef, useContext } from 'react';
import FloorplanTile from '../components/FloorplanTile';
import FloorplanDetails from '../components/FloorplanDetails';
import { useCurrentWidth } from '../hooks';

const Wrap = styled.div`

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 80px 36px;
  
  .floorplanTile {
    margin-bottom: 60px;
    border-bottom: 1px solid black;
    padding-bottom: 60px;
  }

  @media (min-width: ${props => props.theme.bp.smallmedium}) {
    padding: 40px 30px;
    flex-direction: row;

    .floorplanTile {
      flex: 0 0 50%;
      margin: 60px 0;
      padding: 0 20px;
      border-bottom: none;

      &:nth-child(2n+1):not(:first-child):before, 
      &:nth-child(3n+1):not(:first-child):before {
        content: '';
        border-bottom: 1px solid black;
        width: calc(100% - 80px);
        left: 40px;
        position: absolute;
        margin-top: -60px;
      }

      &:nth-child(3n+1):not(:first-child):before {
        display: none;
        width: calc(100% - 120px);
        left: 60px;
      }
    }

  }

  @media (min-width: ${props => props.theme.bp.xlarge}) {
    padding: 60px 30px;

    .floorplanTile {
      flex: 0 0 33.3333%;
      padding: 0 30px;

      &:nth-child(2n+1):not(:first-child):before {
        display: none;
      }

      &:nth-child(3n+1):not(:first-child):before {
        display: block;
      }

    }

  }

`


export default forwardRef(({activePlan, floorplans, togglePlan, offsetHeight=0}, ref) => {
  const theme = useContext(ThemeContext);
  const [ displayedFloorplans, setDisplayedFloorplans ] = useState([]);
  const currentWidth = useCurrentWidth();

  useEffect(() => {
    if (!activePlan) {
      setDisplayedFloorplans(floorplans);
    } else {
      let numCols = 1;
      if (currentWidth >= parseInt(theme.bp.large)) {
        numCols = 3;
      } else if (currentWidth >= parseInt(theme.bp.medium)) {
        numCols = 2;
      }

      let index = floorplans.findIndex(obj => obj.id === activePlan.id);
      let nextIndex = index + 1;
      const mod = nextIndex % numCols;
      if (nextIndex % numCols !==0) {
        nextIndex = Math.min(nextIndex + numCols - mod, floorplans.length);
      }
      const displayed = [...floorplans];
      displayed.splice(nextIndex, 0, {...activePlan, detailed: true});
      setDisplayedFloorplans(displayed);
    }
  }, [activePlan, floorplans, currentWidth, theme])

  const toggle = (obj, ref) => {
    if (activePlan && obj.id === activePlan.id) {
      if (ref?.current?.previousSibling) {
        window.scrollTo({ top: ref.current.previousSibling.offsetTop - 40 - offsetHeight, behavior: 'smooth' });
        setTimeout(() => {
          togglePlan(obj);
        }, 300);
      } else 
      togglePlan(obj);
    } else {
      togglePlan(obj);
    }
  }


  return     <Wrap ref={ ref } className="floorplanList">
  { displayedFloorplans && displayedFloorplans.map(obj => {
    const Comp = obj.detailed ? FloorplanDetails : FloorplanTile;
    return <Comp key={`${obj.id}-${obj.detailed ? 'd' : 't'}`} toggle={ toggle } floorplan={ obj } offsetHeight={ offsetHeight }/>      
  }) }
  </Wrap>

})

