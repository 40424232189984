import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FloorplanSoldOut = styled.div`
  z-index: 2;
  position: absolute;
  top: calc(50% - 21px);
  width: 100%;
  text-align: center;
  background: #fff;
  padding: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.2em;
`

const Main = styled.div`
`

export default () => {
  const { t } = useTranslation();
  const soldOutMessage = t('This home is no longer available')

  return <FloorplanSoldOut className={ `floorplanSoldOut` }>
    <Main>
      { soldOutMessage }
    </Main>
  </FloorplanSoldOut>
} 