import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const init = (resources => {
    i18n
        .use( initReactI18next ) // passes i18n down to react-i18next
        .init( {
            resources,
            lng: window.location.pathname.startsWith( "/zh" ) ? "zh" : "en",// window.localStorage ? (localStorage.getItem('tesoroLang') || "en") : "en",
            fallbackLng: "en",

            keySeparator: false, // we do not use keys in form messages.welcome
            nsSeparator: '~',

            interpolation: {
                escapeValue: false // react already safes from xss
            },
        } );
})

const initFromBackend = ( url ) => {

    const resources = {
        en: { translation: {} },
        zh: { translation: {} }
    };

    return fetch( url, { headers: { 'Content-Type': 'application/json' } } )
        .then( response => response.ok && response.json() )
        .then( json => {

            if ( json ) {
                if ( json.translations ) {
                    json.translations.forEach( item => {
                        if ( item.en ) {
                            resources.en.translation[item.key] = item.en;
                        }
                        if ( item.zh ) {
                            resources.zh.translation[item.key] = item.zh;
                        }
                    } )
                }
            }

            init( resources );

        } ).catch( err => {
            init( resources );
        } )


}

// https://www.i18next.com/how-to/add-or-load-translations
// ADD AFTER INIT
// i18next.init({ resources: {} });
// i18next.addResourceBundle('en', 'namespace1', {
//   key: 'hello from namespace 1'
// });


export { init, initFromBackend }