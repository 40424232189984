import styled from 'styled-components';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../components/layout/Page';
import Hero from '../components/Hero';
import Image from '../components/Image';
import { Link } from "react-router-dom";
import { HeroH1, HeroH2, P, ButtonSpan } from "../components/Typography";
import SimpleContent from '../components/SimpleContent';
import SimpleCTA from '../components/SimpleCTA';
import { SpacedSection } from '../components/layout/Sections';
import VideoWrapper from "../components/VideoWrapper";

const CTASections = styled.div`
  section {
  }
`

const HomeSimpleHeroH2 = styled(HeroH2)`
  margin: 60px 0 0 0 !important;
`

const HomeSimpleCTA = styled.div`
  margin: 20px 0 0 0 !important;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 36px;
  margin-top: 80px;
  margin-bottom: 80px;


  >div:last-child {
    margin-top: 30px;
  }
  @media (min-width: ${props => props.theme.bp.large}) {
    margin: 120px 0;
  }
`


const Section1 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    align-items: center;
    flex-direction: row-reverse;
  }
`
/*
const Section1Old = styled(Section)`
  @media (min-width: ${props => props.theme.bp.large}) {
    padding: 0 60px;
    flex-direction: row-reverse;
    >div {
      flex: 0 0 50%;
    }

    >div:last-child {
      padding: 0;
      display: flex;
      justify-content: center;
      margin-top: 195px;
    }
    .simpleCTA {
      max-width: 320px;
      padding: 0;
      // justify-self: center;
    }
  }

`
*/

const Section2 = styled(SpacedSection)`
  @media (min-width: ${props => props.theme.bp.large}) {
    align-items: center;
  }
`

const Section2Old = styled(Section)`
  @media (min-width: ${props => props.theme.bp.large}) {
    padding: 0 60px;
    flex-direction: row;

    >div:first-child {
      flex: none;
      width: 70.51724%;
    }

    >div:last-child {
      padding: 0;
      display: flex;
      margin-top: 0;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    .simpleCTA {
      max-width: 212px;
      padding: 0;
    }
  }

`
const Section3 = styled(Section)`
  @media (min-width: ${props => props.theme.bp.large}) {
    padding: 0 60px 0 14.921875%;

    >div:last-child {
      padding: 0;
      margin-top: 95px;
    }

    .simpleCTA {
      max-width: 367px;
    }
  }
  
`

const Section4 = styled(Section)`
  padding: 0;
  >div:last-child {
    padding: 0 36px;
  }


  @media (min-width: ${props => props.theme.bp.large}) {

    >div:last-child {
      padding: 0 207px 0 191px;
      margin-top: 80px;
    }

    .simpleCTA {
      display: flex;

      h3, p, a {
        margin: 0;
        flex: none;
      }

      h3, p {
        padding-right: 60px;
      }

      h3 {
        width: 50%;
        // 882
      }

      p {
        width: calc(50% - 145px);
      }

      a {
        width: 145px;
        display: block;
        margin-top: 0;
        flex: none;

        span {
          width: 100%;
        }
      }
    }
  }

`

const Section5 = styled(Section2Old)`
  @media (min-width: ${props => props.theme.bp.large}) {
    >div:first-child {
      width: 74.91379%;
    }
  }
`

const Section6 = styled(Section)`
  @media (min-width: ${props => props.theme.bp.large}) {
    padding: 0 60px;
    flex-direction: row-reverse;
    align-items: flex-end;
    
    >div {
      flex: 1;
      width: 100%;

      &:first-child {
        flex: none;
        width: 68.53448%;
      }
    }

    >div:last-child {
      padding: 0;
      margin-top: 0;
    }

    .simpleCTA {
      max-width: 211px;
    }
  }

`

const Section7 = styled(Section2Old)`
  @media (min-width: ${props => props.theme.bp.large}) {
    padding: 0 60px 0 0;
    justify-content: flex-end;
    >div:first-child {
      width: 46.80328%;
    }

    >div:last-child {
      flex: none;
      justify-content: flex-end;
      margin-left: 109px;
    }

    .simpleCTA {
      max-width: 339px;
      padding: 0;
    }

  }
`

const langUrl = (url) => {
  const isZh = window.location.pathname.includes("/zh");
  return `${isZh ? "/zh" : ""}${url}`;
};

export default () => {
  const { t } = useTranslation();
  const transitionRef = useRef();

  return (
    <Page title={false} header={{ transitionRef }}>
      <Hero
        ref={transitionRef}
        coverImage="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/06/HomeHero.jpg"
      >
        <HeroH1>
          {t("This Singular Urban Cove.")} <br />
          {t("This Coveted Lifestyle.")}
        </HeroH1>
        <HeroH2>
          {t("At the Crest of False Creek, in the Heart of the City.")}
        </HeroH2>
      </Hero>
      <SimpleContent>
        <HeroH1>{t("A Treasured Existence")}</HeroH1>
        <P>
          {t(
            "The prestige of landmark West Coast modern architecture. The timeless elegance of contemporary Italian design."
          )}
        </P>
        <P>
          {t(
            "Vancouver’s most iconic views from this prime waterfront perspective – the sunlit water reflected in the gleaming glass city, the mountains towering majestically behind."
          )}
        </P>
        <HomeSimpleHeroH2>
          {t("Now Selling By Private Appointment")}
        </HomeSimpleHeroH2>
        <HomeSimpleCTA>
          <P>{t("Yet a superb selection of both waterfront and city collection homes is yours to discover.")}</P>
        </HomeSimpleCTA>
        <HomeSimpleCTA>
          <Link to={langUrl("/enquire")}>
            <ButtonSpan className="inverted">{t("Book Your Private Appointment")}</ButtonSpan>
          </Link>
        </HomeSimpleCTA>
      </SimpleContent>
      <CTASections>
        <Section1
          className="og"
          gutterFirst={191}
          first={416}
          second={320}
          gutterSecond={133}
        >
          <div>
            <Image
              src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/Home_Location_Introduction.jpg"
              alt=""
            />
          </div>
          <div>
            <SimpleCTA
              headline={t("At Sea at the Heart of the City")}
              content={t(
                "Vancouver’s most pivotal location, where coveted neighbourhoods intersect and a lively inlet of water meets land. The city’s best outside your door."
              )}
              buttonLink="/location"
              buttonLabel={t("Location")}
            />
          </div>
        </Section1>

        <Section2 className="og" first={630} second={212} gutterSecond={126}>
          <div>
            <Image
              src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/08/Home_Residences_Introduction.jpg"
              alt=""
            />
          </div>
          <div>
            <SimpleCTA
              headline={t("A New West Coast Icon")}
              content={t(
                "Landmark West Coast modern architecture presents a new icon to our city, at the crest of False Creek."
              )}
              buttonLink="/residences"
              buttonLabel={t("Residences")}
            />
          </div>
        </Section2>

        <Section3>
          <div>
            <Image
              src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Home_Floorplans_Introduction.jpg"
              alt=""
            />
          </div>
          <div>
            <SimpleCTA
              headline={t("An Abundance of Space")}
              content={t(
                "A contemporary interpretation of classic luxury, with careful attention to the unique experience of each home’s spacious floorplan."
              )}
              buttonLink="/floorplans"
              buttonLabel={t("Floorplans")}
            />
          </div>
        </Section3>

        <Section4>
          <Image
            src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Home_Views_Introduction.jpg"
            alt=""
          />
          <div>
            <SimpleCTA
              headline={t("These Tiered Vistas of Mountain, City, and Sea")}
              content={t(
                "Each waterfront home is designed to capture Vancouver’s most iconic view."
              )}
              buttonLink="/views"
              buttonLabel={t("Views")}
            />
          </div>
        </Section4>
        <Section5>
          <div>
            <Image
              src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Home_Amenities_Introduction.jpg"
              alt=""
            />
          </div>
          <div>
            <SimpleCTA
              headline={t("A Lifestyle")}
              content={t("A rich welcome to the promise of a way of life.")}
              buttonLink="/amenities"
              buttonLabel={t("Amenities")}
            />
          </div>
        </Section5>
        <Section6>
          <div>
            <VideoWrapper
              image="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/10/Air_VideoThumbnail.jpg"
              video="https://player.vimeo.com/video/469113128"
              bg={true}
            />
          </div>
          <div>
            <SimpleCTA
              headline={t("The Moments We Treasure")}
              content={t(
                "The experience of TESORO; a rare and treasured existence."
              )}
              buttonLink="/videography"
              buttonLabel={t("Videography")}
            />
          </div>
        </Section6>
        <Section7>
          <div>
            <Image
              src="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Home_Developer_Introduction.jpg"
              alt=""
            />
          </div>
          <div>
            <SimpleCTA
              headline={t("Concert Properties")}
              content={t(
                "Over 30 years and 100 awards later, Concert Properties has grown into one of the most trusted and respected names in real estate."
              )}
              buttonLink="/developer"
              buttonLabel={t("Developer")}
            />
          </div>
        </Section7>
      </CTASections>
    </Page>
  );
}