import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import LangPicker from '../LangPicker';
import { ReactComponent as MenuBar } from '../../assets/MenuBar.svg';
import { ReactComponent as TesoroLogo } from '../../assets/TesoroLogo.svg';
import { useIntersectionObserver } from '../../hooks';

const Header = styled.header`
  position: absolute;
  height: 0;
  width: 100%;
  z-index: 99;

  .logo, >.lang {
    position: absolute;
    top: 47px;
  }

  .logo {
    left: 50%;
    transform: translateX(-50%);
  }

  >.lang {
    right: 164px;
  }

  &.noEnquire >.lang {
    right: 60px;
  }

  >.lang, .enquire {
    color: white;
  }

  // .enquire {
  // }

  >.menuBar {
    position: fixed;
    top: 47px;
    left: 60px;
    cursor: pointer;

    line, path {
      stroke: white;
      transition: all 0.2s ease;
    }

    &.dark {
      line, path {
        stroke: black;
      }
    }
  }

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    >.lang {
      display: none;
    }

    // .enquire {
    //   right: 30px;
    //   letter-spacing: 2.8px;
    //   font-size: 8px;
    // }

    >.menuBar {
      left: 36px;
    }
  }
`

const Enquire = styled(Link)`
  color: white;
  display: inline-block;
  font-weight: bold;
  letter-spacing: 3.33px;
  line-height: 16px;
  overflow: hidden;
  padding-bottom: 2px;
  position: fixed;
  right: 60px;
  text-decoration: none;
  text-transform: uppercase;
  top: 47px;
  transition: color 0.2s ease;
  z-index: 100;

  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    border-bottom: 1px solid white;
    width: 100%;
    left: 0;
    transform: translateX(-100%);
    transition: border-bottom 0.2s ease, transform 0.5s ease-in-out;
  }

  &.dark {
    color: black;
    &:after {
      border-bottom: 1px solid black;
    }
  }

  &.lineActive {
    &:after {
      transform: translateX(0);
    }
  }

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    right: 36px;
    letter-spacing: 2.8px;
    font-size: 8px;
    z-index: 50;
  }

`

const MenuShim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 80;
`

const langUrl = (url, lang) => {
  const isZh = window.location.pathname.includes("/zh");
  return `${isZh ? "/zh" : ""}${url}`;
}

export default ({ enquire=true, transitionRef=null }) => {
  const { t } = useTranslation();
  const [ menuActive, setMenuActive ] = useState(false);
  const [ darkMode, setDarkMode ] = useState(false);
  const [ lineActive, setLineActive ] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setLineActive('lineActive');
    }, 400);
  }, [])

  useIntersectionObserver({
    target: transitionRef,
    rootMargin: '-100px',
    onIntersect: ([{ isIntersecting }], observerElement) => {
      setDarkMode(!isIntersecting);
    }
  })

  return <>
    { menuActive && <MenuShim onClick={ () => setMenuActive(false) }/> }
    <Header className={enquire ? 'enquire' : 'noEnquire'}>
      <MenuBar className={`menuBar ${darkMode ? 'dark' : ''}`} onClick={() => setMenuActive(!menuActive)} />
      <Link to={langUrl("/")} className="logo"><TesoroLogo /></Link>
      <LangPicker className="lang" />
      <Menu active={menuActive} setActive={setMenuActive} />
    </Header>
    { enquire && <Enquire className={`enquire ${darkMode ? 'dark' : ''} ${lineActive}`} to="/enquire">{ t('Enquire') }</Enquire> }
  </>
}