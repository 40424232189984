import styled from 'styled-components';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuClose } from '../../assets/MenuClose.svg';

import LangPicker from '../LangPicker';

const Menu = styled.div`
  background-color: #C5B9AC;
  font-size: 20px;
  position: fixed;
  width: 340px;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 300ms ease;
  transform: translateX(-100%);

  &.active {
    transform: translateX(0);
  }

  ul {
    text-align: center;
    display: inline-block;
    margin: 0;
    list-style: none;

    li {
      padding: 0.6em 0;
      a {
        font-size: 1em;
        line-height: 1.1em;
        text-decoration: none;
        font-family: 'AltrinchamW01';
        letter-spacing: .1rem;
        text-transform: uppercase;
        line-height: 1.4;
        font-weight: 500;
        color: white;  
        position: relative;

        &.active:after {
          content: '';
          position: absolute; 
          bottom: -2px;
          border-bottom: 1px solid white;
          width: 100%;
          left: 0;
        }      
      }
    }
  }

  .menuClose {
    position: absolute;
    top: 49px;
    right: 45px;
    cursor: pointer;
    line, path {
      stroke: white;
    }      

  }

  .lang {
    font-size: 11px;
    color: white;
    position: absolute;
    bottom: 50px;
  }

`

const langUrl = (url) => {
  const isZh = window.location.pathname.includes("/zh");
  return `${isZh ? "/zh" : ""}${url}`;
}

export default ({ active, setActive }) => {
  const { t } = useTranslation();



  return <Menu className={active ? 'active' : ''}>
    <nav>
      <ul>
        <li><NavLink to={langUrl("/location")}>{ t('Location') }</NavLink></li>
        <li><NavLink to={langUrl("/residences")}>{ t('Residences') }</NavLink></li>
        <li><NavLink to={langUrl("/views")}>{ t('Views') }</NavLink></li>
        <li><NavLink to={langUrl("/floorplans")}>{ t('Floorplans') }</NavLink></li>
        <li><NavLink to={langUrl("/amenities")}>{ t('Amenities') }</NavLink></li>
        <li><NavLink to={langUrl("/videography")}>{ t('Videography') }</NavLink></li>
        <li><NavLink to={langUrl("/developer")}>{ t('Developer') }</NavLink></li>
        <li><NavLink to={langUrl("/enquire")}>{ t('Enquire') }</NavLink></li>
      </ul>    
    </nav>
    <MenuClose className="menuClose" onClick={() => setActive(false)} />
    <LangPicker className="lang" />
  </Menu>
}