import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FloorplanH3, FloorplanLabel } from '../components/Typography';

const FloorplanTitle = styled.div`
`

const Main = styled.div`
  display: flex;

  h3 {
    margin-bottom: 4px;
    &:not(:last-child) {
      padding-right: 1em;
      margin-right: 1em;
      border-right: 1px solid black;

    }
  }
`

export default ({ plan, unit, collection, className}) => {
  const { t } = useTranslation();
  const collectionLabel = {
    waterfront: t('Waterfront Collection'),
    city: t('City Collection'),
    townhome: t('Townhome Collection')
  };

  return <FloorplanTitle className={ `floorplanTitle ${className}` }>
    <Main>
      <FloorplanH3>{ plan }</FloorplanH3>
      { unit && <FloorplanH3>{ unit }</FloorplanH3> }
    </Main>
    <FloorplanLabel>{ collectionLabel[collection] }</FloorplanLabel>
  </FloorplanTitle>
} 