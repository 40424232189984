import { useState, useEffect } from 'react';
import { apiUrl } from './config';

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

const useCurrentWidth = () => {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return width;
}

const useFloorplans = () => {
  const [ floorplans, setFloorplans ] = useState([]);

  useEffect(() => {
    const url = apiUrl('/floorplan?per_page=100&orderby=title&order=ASC');
    fetch(url, {headers: { 'Content-Type': 'application/json' }}).then(response => response.ok && response.json()).then(json => {
      setFloorplans(json.map(item => {
        const id = item.id;
        let slug = item.acf.name.toLowerCase().replace(/ /g, '-');
        if (item.acf.unit) {
          slug += ('-' + item.acf.unit.replace(' - ', '-'));
        }
        slug = slug.toLowerCase().replace(/ /g, '-');

        let bedroomSlug = `${item.acf.bedrooms}-bedroom`;
        if (item.acf.extraRoom) {
          bedroomSlug += ('-' + item.acf.extraRoom.toLowerCase().replace(/\+ /g, '').replace(/\+/g, '').replace(/ /g, '-'));
        }


        item.acf.floors = item.acf.floors ? item.acf.floors.map(f => parseInt(f)) : [];

        /* setup default value for Floorplan.isSoldOut */
        item.acf.isSoldOut = item.acf.hasOwnProperty( 'isSoldOut' ) && item.acf.isSoldOut === true

        return { id, slug, bedroomSlug, ...item.acf };
      }));
    }).catch(err => {
      console.log('error loading floorplans', err);
      // init(resources);
    });
  

  }, [ setFloorplans ]);


  return floorplans;  
}

const useGallery = () => {
  const [ gallery, setGallery ] = useState([]);

  useEffect(() => {
    const url = apiUrl('/options/tesoro-gallery');
    fetch(url, {headers: { 'Content-Type': 'application/json' }}).then(response => response.ok && response.json()).then(json => {
      setGallery(json?.acf?.gallery);
    }).catch(err => {
      console.log('error loading gallery', err);
      // init(resources);
    });
  

  }, [ setGallery ]);


  return gallery;  
}

const useIntersectionObserver = ({
  target,
  src = null,
  onIntersect,
  threshold = 0,
  rootMargin = "0px",
  enabled = true
}) => {

  useEffect(() => {
    if (target) {
      const current = target.current;
      let observer;
      if (enabled) {
        observer = new IntersectionObserver(onIntersect, {
          rootMargin,
          threshold,
          root: src ? src.current : null
        });

        observer.observe(current);
      }

      return () => {
        if (enabled) {
          observer.unobserve(current);
        }
      };
    }
  });
};



export { useCurrentWidth, useFloorplans, useGallery, useIntersectionObserver };