import styled from 'styled-components';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import i18n from "i18next";

const FormSelectWrap = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid #fff;

  select {
    cursor: pointer;
    position: absolute;
    // width: 100%;
    // height: 100%;
    width: 0;
    height: 0;
    top: 0; 
    left: 0;
    opacity: 0;
  }
`

const Options = styled.div`
  display: none;
  width: calc(100% + 40px);
  background: black;
  margin: 15px -20px 0;
  padding: 0 20px;
  position: absolute;
  z-index: 1;

  &.active {
    display: block;
    z-index: 9;
  }

  .form & {
    background-color: #C5B9AC;
    margin-top: 1px;
  }

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    max-height: 350px;
    overflow: auto;
  }

`

const Option = styled.div`
  border-top: 1px solid #C5B9AC;
  font-size: 11px;
  letter-spacing: 2.63px;
  color: #C5B9AC;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 30px;
  padding: 10px 0;
  cursor: pointer;

  &.is-disabled { 
    color: #46413C; 
  }
  
  .form & {
    border-top: none;
    border-bottom: 1px solid white;
    color: white;
    text-align: center;  
    padding: 5px 0;  
  }

  .form &.active {
    background-color: rgba(255, 255, 255, 0.25);
  }


  &.active {
    color: white;
  }
  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    text-align: center;
  }
`

export default ({ name, id, selected=null, placeholder, placeholderValue, onChange, className, options=[] }) => {
  const [ active, setActive ] = useState(false);
  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ cur, setCur ] = useState(placeholder);
  const language = i18n.language;
  const ref = useRef();

  const updateLabel = useCallback(() => {
    let current = selectedIndex >= 0 ? options[selectedIndex] : null;
    let label = current ? current.label : null;

    if (!label || (current && placeholder && placeholderValue === current.value)) {
      label = placeholder;
    }

    setCur(label);
  }, [placeholder, selectedIndex])

  const setSelected = useCallback((sel, callOnChange=true) => {
    setSelectedIndex(options.findIndex(el => el.value === sel))
    setActive(false);

    if (callOnChange && onChange) {
      onChange(sel);
    }
  }, [options, onChange])

  useEffect(() => {
    const onClick = e => {
      if (active) {
        const rect = ref.current.getBoundingClientRect();
        if (!(e.x >= rect.left && e.x <= rect.right && e.y >= rect.top && e.y <= rect.bottom)) {
          setActive(false);
        }
      }
    }

    document.addEventListener('click', onClick);

    return () => {
      document.removeEventListener('click', onClick);      
    }

  }, [ active, setActive ]) 

  useEffect(() => {
    updateLabel();
  }, [language, updateLabel])

  useEffect(() => {
    if (selected !== null) {
      setSelected(selected, false);
    }
  }, [selected, setSelected, options])

  const classNames = list =>
      list.filter( item => item !=='' ).join(' ')

  return <FormSelectWrap ref={ ref } className={`formElementDropdown ${cur === placeholder ? 'isPlaceholder' : ''} ${className || ''}`}>
    <label onClick={ () => setActive(!active) }>{ cur }</label>
    <Options className={ active ? 'active' : '' }>
      { options && options.map((option, idx) => <Option key={idx} className={ classNames([selectedIndex === idx ? 'active' : '', option.disabled ? 'is-disabled' : ''])  } onClick={ () => setSelected(option.value) }>{ option.label }</Option>) }
    </Options>
  </FormSelectWrap>
}
