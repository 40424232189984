import styled from "styled-components";
import React, { useRef, useState } from "react";
import { useIntersectionObserver } from "../hooks";
import TransitionBlock from "./TransitionBlock";

const VideoWrapperElement = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    position: relative;
    width: 100%;
    height: auto;
  }

  iframe {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
  }

  button {
    position: absolute;
    z-index: 3;
    top: calc(50% - 39px);
    left: calc(50% - 39px);
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
    cursor: pointer;
    background: transparent no-repeat center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='76.931' height='76.931' viewBox='0 0 76.931 76.931'%3E%3Cg id='Group_1945' data-name='Group 1945' transform='translate(-769.893 310.515)'%3E%3Cpath id='Path_30' data-name='Path 30' d='M811.161-242.356V-278.01l30.455,17.827Z' transform='translate(-15.166 -11.866)' fill='%23fff'/%3E%3Ccircle id='Ellipse_29' data-name='Ellipse 29' cx='37.466' cy='37.466' r='37.466' transform='translate(770.893 -309.515)' fill='none' stroke='%23fff' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");

    &:focus {
      outline: none;
    }
  }
`;

export default ({
  image = null,
  video = null,
  bg = false,
}) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [playing, setPlaying] =
    bg && isVisible ? useState(true) : useState(false);
  const [videoURL, setVideoURL] =
    bg && isVisible
      ? useState(video + "?background=1&autoplay=1")
      : useState(video); 

  useIntersectionObserver({
    target: ref,
    threshold: 0,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        if (!isVisible) {
          setIsVisible(true);
          onShow();
        }
        observerElement.unobserve(ref.current);
      }
    },
  });

  const onShow = (e) => {
    if (bg) {
      setPlaying(true);
      setVideoURL(video + "?background=1&autoplay=1");
    }
  }

  const playVideo = (e) => {
    e.preventDefault();
    setPlaying(true);
    setVideoURL(video + "?autoplay=1"); // ?controls=0&autoplay=1
  };

  return (
    <TransitionBlock
      opacityDuration="0.8s"
      offsetDuration="1.5s"
      fromOffsetY={100}
      threshold={0}
    >
      <VideoWrapperElement ref={ref} className={playing ? "playing" : ""}>
        <img src={image} alt="Video" />
        <iframe
          src={videoURL}
          style={!playing ? { visibility: "hidden" } : {}}
          width="640"
          height="338"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
        <button
          style={playing ? { visibility: "hidden" } : {}}
          onClick={(e) => playVideo(e)}
        ></button>
      </VideoWrapperElement>
    </TransitionBlock>
  );
};
