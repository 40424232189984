import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { H3, P, ButtonSpan } from './Typography';
import TransitionBlock from './TransitionBlock';

const Content = styled.div`
  color: #A39383;
  
  a {
    display: block;
    margin-top: 55px;
  }
`
const langUrl = (url) => {
  const isZh = window.location.pathname.includes("/zh");
  return `${isZh ? "/zh" : ""}${url}`;
}

export default ({ headline, content, buttonLink, buttonLabel }) => {

  return <TransitionBlock opacityDuration="0.6s" offsetDuration="0.8s" fromOffsetY={80} threshold={0}>
    <Content className="simpleCTA">
    { headline && <H3>{ headline }</H3> }
    <P>{ content }</P>
    <Link to={langUrl(buttonLink)}><ButtonSpan>{ buttonLabel }</ButtonSpan></Link>
    </Content>
  </TransitionBlock>
} 