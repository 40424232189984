import styled from 'styled-components';
import React, { useEffect } from 'react';
import EnquireCTA from '../EnquireCTA';
import Header from './Header';
import Footer from './Footer';
import i18n from "i18next";

const Page = styled.div`

`
export default ({ title, header, children }) => {
  const titleSuffix = "TESORO by Concert";

  useEffect(() => {
    window.scrollTo({ top: 0 /*, behavior: 'smooth'*/ }) 
  }, [])

  useEffect(() => {
    if (title === false) {
      document.title = titleSuffix;
    } else if (title) {
      document.title = `${title} - ${titleSuffix}`;
    }
  }, [ title, titleSuffix ])

  return <>
    <Header {...header} />
    <Page className={i18n.language === 'en' ? 'lang-en' : 'lang-zh'}>{ children }</Page>
    { (!header || header.enquire !== false) && <EnquireCTA className={i18n.language === 'en' ? 'lang-en' : 'lang-zh'} /> }
    <Footer />
  </>
}