// import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeroH1 } from '../components/Typography';
import Page from '../components/layout/Page';
import Hero from '../components/Hero';

export default () => {
  const { t } = useTranslation();

  return <Page title="Thank You" header={{ enquire: false }}>
    <Hero color="#C5B9AC" downArrow={false}>
      <HeroH1>{ t('Thank You For Your Interest In TESORO') }</HeroH1>
    </Hero>
  </Page>
}