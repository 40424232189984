import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGallery } from '../hooks';
import Captioned from '../components/Captioned';
import Flickity from 'react-flickity-component'
import { ReactComponent as MenuClose } from '../assets/MenuClose.svg';


const Gallery = styled.div`
  background-color: black;
  position: relative;
  overflow: hidden;
  height: 100%;

  .flickity-enabled {
    outline: none;
    height: 100%;
  }

  .flickity-viewport {
    height: 100%;
  }

  .flickity-slider {
    height: 100%;
  }

  .flickity-prev-next-button {
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    background: none;
    padding: 10px;
    cursor: pointer;

    &:disabled {
      display: none;
    }

    &.previous {
      left: 10px;
    }

    &.next {
      right: 10px;
    }


    path {
      fill: white;
    }
  }

`

const Slide = styled.div`
  display: flex;
  justify-content: center;

  .caption {
    color: white;
    margin-top: 20px;
  }

  @media screen and (orientation:portrait) {
    width: 100%;
    height: 100%;
    padding: 0 0 76px;

    .captioned {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .caption {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
    }
  }

  @media screen and (orientation:landscape) {
    height: 100%;
    margin: 0 40px;
    padding: 20px 0;
  
    img {
      width: auto;
      height: 100%;
      transition: all 0.3s ease;
      transform: scale(0.5);
      opacity: 0.5;
    }

    .captioned {
      height: calc(100% - 20px - 36px);
      display: inline-block;
    }

    .caption {
      display: none;
    }
  
    &.is-selected {
      .caption {
        display: block;
      }
      img {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
`

const Close = styled(Link)`
  position: fixed;
  top: 25px;
  right: 25px;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;

    line {
      stroke: white;
    }
  }
`

const flickityOptions = {
  initialIndex: 0,
  pageDots: false,
  lazyLoad: 3,
  prevNextButtons: true,
  setGallerySize: false,
}

export default () => {
  const { t } = useTranslation();
  const gallery = useGallery();

  return <Gallery>
    { gallery && gallery.length && <Flickity options={flickityOptions} static>
        { gallery.map(image => <Slide key={image.id} ><Captioned caption={ t(image.title) } caption2={ t(image.caption) }><img data-flickity-lazyload={image.url} className="carousel-image" alt="" /></Captioned></Slide>)}
      </Flickity>
    }
    <Close to="/location" ><MenuClose /></Close>
  </Gallery>
}