import styled from 'styled-components';
import React from 'react';

const Captioned = styled.div`
`

const Caption = styled.div`
  color: ${props => props.textColor};
  font-size: 13px;
  letter-spacing: 0.97px;
  line-height: 1.384615em;
  margin-top: ${props => props.inline ? "-80px" : "60px"};
  position: relative;
  text-align: right;
  padding-right: 174px;

  ${props => props.inline ? 'float: right;' : ''}

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    margin-top: ${props => props.inline ? "-50px" : "20px"};
    font-size: 10px;
  }


  &:after {
    content: '';
    position: absolute;
    top: 5px;
    right: 0;
    width: 121px;
    border-top: 1px solid ${props => props.textColor};
  }

  @media (min-width: ${props => props.theme.bp.large}) and (max-width: calc(${props => props.theme.bp.xlarge} - 1px)) {
    padding-right: 0;
    &:after {
      display: none;
    }
  }

`

export default ({caption, caption2, color="#A39383", inline=false, children}) => {
  return <Captioned className="captioned">
    { children }
    <Caption textColor={color} inline={inline} className="caption">
      { caption }
      <br/>&nbsp;{ caption2 }
    </Caption>
  </Captioned>
} 