import styled from 'styled-components';


const Section = styled.section`
  color: #A39383;
  padding: 0 36px;
  margin-top: 80px;
  margin-bottom: 80px;

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    &:not(.og) {
      p {
        font-size: 12px;
        line-height: 17px;
        margin-top: 80px;
        padding-left: 22%;
        margin-bottom: 80px;
      }
      p:last-child {
        margin-bottom: 80px;      
      }
    }
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    margin-top: 120px;
    margin-bottom: 120px;
    padding-left: ${props => props.gutterFirst || 60}px;
    padding-right: ${props => props.gutterSecond || 60}px;
  }
`

const PercentageSection = styled(Section)`
  @media (min-width: ${props => props.theme.bp.large}) {
    padding-left: ${props => props.portionLeft / 1280 * 100}%;
    padding-right: ${props => props.portionRight / 1280 * 100}%;
  }
`


// percentage left, percentage right, 60px gutters
const SpacedSection = styled(Section)`
  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    >div:last-child {
      margin-top: 40px;
    }
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    display: flex;
    flex-direction: ${props => props.direction || 'row'};
    justify-content: space-between;

    >div:first-child {
      width: calc(${props => props.first} / (1280 - ${props => props.gutterFirst || 60} - ${props => props.gutterSecond || 60}) * 100%);
      flex: none;
    }

    >div:last-child {
      flex: none;
      width: calc(${props => props.second} / (1280 - ${props => props.gutterFirst || 60} - ${props => props.gutterSecond || 60}) * 100%);
    }
  }
`

const InfoSection = styled(SpacedSection)`
  background-color: #C5B9AC;
  margin-top: 0;
  padding-top: 80px;
  padding-bottom: 80px;
  
  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    p, p:last-child {
      padding: 0;
      margin: 0 44px 60px;
    }
    >div:last-child {
      margin-top: 0;
    }
  }

  >div:first-child {
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    margin-top: 0;
    padding-top: 120px;
    padding-bottom: 120px;
  }  
`


export { Section, PercentageSection, SpacedSection, InfoSection };