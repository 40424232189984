import styled from 'styled-components';
import React from 'react';

const Content = styled.section`

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #C5B9AC;
  min-height: 394px;
  text-align: center;
  padding: 150px 36px 130px;
  color: white;

  h1, h2 {
    margin-bottom: 1em;
  }

`
const Inner = styled.div`
  max-width: 510px;
`

export default ({ children }) => {

  return <Content>
    <Inner>
      { children }
    </Inner>
  </Content>
} 