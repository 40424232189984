import styled from 'styled-components';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../components/layout/Page';
import Hero from '../components/Hero';
import { HeroH1, ButtonSpan } from '../components/Typography';
import { useFloorplans } from '../hooks';
import FloorplanList from '../components/FloorplanList';
import { ReactComponent as ViewOpen } from '../assets/Expand_CTA.svg';
import { ReactComponent as ViewClose } from '../assets/MenuClose.svg';
import Flickity from 'react-flickity-component'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemContext
} from "@reach/accordion";
import "@reach/accordion/styles.css";


const Title = styled(HeroH1)`
  color: #A39383;
  margin: 123px 0;
`

const Main = styled.main`
  padding: 0 36px 80px;

  @media (min-width: ${props => props.theme.bp.large}) {
    padding: 0 60px 123px;
  }
`

const StyledFloorplanList = styled.div`

  .floorplanList {
    padding: 0;

    @media (min-width: ${props => props.theme.bp.large}) {
      padding: 17px 0 34px;
    }    
  }

`

const StyleAccordion = styled(Accordion)`
  border-bottom: 1px solid #A39383;

  [data-reach-accordion-item] {
    border-top: 1px solid #A39383;
  }

  [data-reach-accordion-button] {
    outline: none;
    display: block;
    color: #A39383;
    cursor: pointer;
    background: none;
    width: 100%;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
    font-family: inherit;
    letter-spacing: 3px;
    line-height: 72px;
    position: relative;

  }

  [data-reach-accordion-panel] {
    outline: none;
    padding-bottom: 65px;
  }
`
/*
const ViewImageWrap = styled.div`
  position: relative;
`

const ViewImage = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 63px;

  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }


  img {
    height: 100%;
    width: auto;

  }
`

const ViewImageNav = styled.div`
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%) rotate(${props => props.rotate});
  ${props => props.position}: 10px;

  @media (min-width: ${props => props.theme.bp.medium}) {
    ${props => props.position}: 50px;
  }

  svg {
    width: 20px;
    height: 10px;
  }
`
*/

const ViewAllLink = styled.div`
  text-align: center;
`

const Label = styled.label`
  display: block;
  font-size: 15px;
  color: #A39383;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-family: inherit;
  letter-spacing: 3px;
  position: relative;
  line-height: 18px;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 63px;
  @media (min-width: ${props => props.theme.bp.medium}) {
    margin-bottom: 0;
  }

`

const SliderWrap = styled.div`
  position: relative;

  .flickity-enabled {
    outline: none;
    height: 100%;
  }

  .flickity-viewport {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .flickity-slider {
    height: 100%;
  }

  img {
    width: ${props => Math.round(6000 / 910 * props.slideHeight)}px;
    height: ${props => props.slideHeight}px;
  }

  label {
    display: none;
  }

  button {
    cursor: pointer;
    position: absolute;
    border: none;
    outline: none;
    background: none;
    top: 35px;
    right: 35px;

    width: 154px;
    height: 25px;

    &.active {
      width: 18px;
      height: 18px;
    }
  }

  &.fullscreen {
    height: 100vh;
    width: 100%;
    position: fixed;
    background: black;
    z-index: 110;
    top: 0;

    label {
      font-size: 15px; 
      line-height: 18px;
      top: 64px;
      position: absolute;
      display: block;
      text-align: center;
      letter-spacing: 3px;
      color: #A39383;
      width: 100%;
      text-transform: uppercase;
    }

    .flickity-viewport {
      top: 110px;
      width: 100%;
      position: absolute;
    }

    button {
      top: 64px;

      svg {
        line {
          stroke: #A39383;
        }
      }
    }
  }

  
`

const Slider = ({title, src}) => {
  const { index, isExpanded } = useAccordionItemContext();
  const [ active, toggleActive ] = useState(false);

  const onClick = e => { 
    e.preventDefault(); 
    e.stopPropagation(); 
    toggleActive(!active) 
  };

  const height = active ? Math.min(910, document.body.clientHeight - 110 - 85) : 300;

  const Comp = index >= 0 && isExpanded ? <SliderWrap className={active ? 'fullscreen': ''} slideHeight={height}>
    <Flickity options={{ freeScroll: true, freeScrollFriction: 0.06, wrapAround: true, pageDots: false, prevNextButtons: false }}>
      <img src={ src } alt="" />
      <img src={ src } alt="" />
    </Flickity>
    <label>{ title }</label>
    <button className={active ? "active" : ""} onClick={ onClick }>{ active ? <ViewClose/> : <ViewOpen/> }</button>
  </SliderWrap> : null;

  document.body.classList[Comp && active ? 'add' : 'remove']("modal-active");

  return Comp && active ? ReactDOM.createPortal(Comp, document.body) : Comp;

}


const Views = ({ title, label, view, floorplans, forFloors=[] }) => {
  const { t } = useTranslation();
  const [ activePlan, setActivePlan ] = useState();

  const togglePlan = plan => {
    setActivePlan(!activePlan || activePlan.id !== plan.id ? plan : null);
  }

  return <AccordionItem>
    <AccordionButton>{ title }</AccordionButton>
    <AccordionPanel>
      <Slider title={ title } src={`https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/TESORO_360_Level_${view}-Park-Render_REV.jpg`} />
      <Label>{ label }</Label>
      <StyledFloorplanList><FloorplanList activePlan={activePlan} floorplans={floorplans} togglePlan={togglePlan}/></StyledFloorplanList>
      <ViewAllLink><Link to="/floorplans"><ButtonSpan>{ t('View All Floorplans') }</ButtonSpan></Link></ViewAllLink>

    </AccordionPanel>
  </AccordionItem>
}

export default () => {
  const { t } = useTranslation();
  const transitionRef = useRef();
  const floorplans = useFloorplans();

  const filterFloorplans = forFloors => {
    const viewPlans = floorplans.filter(fp => {
      return fp.floors.filter(f => forFloors.indexOf(f) >= 0).length;
    })

    // some crappy sort logic to make townhomes come first. 
    if (forFloors.indexOf(2) >= 0) {
      viewPlans.sort((a, b) => {
        if (a.collection === 'townhome' && b.collection !== 'townhome') {
          return -1;
        }
        if (a.collection !== 'townhome' && b.collection === 'townhome') {
          return 1;
        }
        if (a.collection === 'townhome' && b.collection === 'townhome') {
          return `${a.name} ${a.unit}`.localeCompare(`${a.name} ${a.unit}`);
        }

        return a.name.localeCompare(b.name);
      })
    }

    return viewPlans;
  }

  return <Page title="Views" header={{ transitionRef }}>
    <Hero ref={ transitionRef } coverImage="https://tesorobyconcert.com/wordpress/wp-content/uploads/2020/07/Home_Views_Introduction.jpg">
      <HeroH1>
        { t('These Tiered Vistas of City,') } <br/>
        { t('Sea and Mountain Peaks.') }
      </HeroH1>
    </Hero>
    <Title>{ t('Iconic Views at Every Level') }</Title>

    <Main>

      {/* add multiple prop to make this not an accordion*/}
      <StyleAccordion collapsible>
        { floorplans && floorplans.length ? <>
        <Views title={ t('Level 16') } view="16" label={ t('Level 16 Homes') } floorplans={filterFloorplans([16])} />
        <Views title={ t('Level 14') } view="14" label={ t('Levels 14-15 Homes') } floorplans={filterFloorplans([14, 15])} />
        <Views title={ t('Level 12') } view="12" label={ t('Levels 12-13 Homes') } floorplans={filterFloorplans([12, 13])} />
        <Views title={ t('Level 10') } view="10" label={ t('Levels 10-11 Homes') } floorplans={filterFloorplans([10, 11])} />
        <Views title={ t('Level 8') } view="08" label={ t('Levels 8-9 Homes') } floorplans={filterFloorplans([8, 9])} />
        <Views title={ t('Level 6') } view="06" label={ t('Levels 6-7 Homes') } floorplans={filterFloorplans([6, 7])} />
        <Views title={ t('Level 4') } view="04" label={ t('Levels 4-5 Homes') } floorplans={filterFloorplans([4, 5])} />
        <Views title={ t('Level 1-3') } view="02" label={ t('Levels 1-3 Homes') } floorplans={filterFloorplans([2, 3])} />
        </> : ''}
      </StyleAccordion>

    </Main>
  </Page>
}


